import * as React from "react";

// Components
import Layout from "../components/Layouts/Layouts";
import Seo from "../components/Layouts/Seo";
import BannerBasic from "../components/Content/Banner/Basic";
import LeadIn from "../components/Content/LeadIn/Index";
import FeatureColumn from "../components/Content/FeatureColumn/Index";

// data
import featuredBrands from "../pages/static-pages/content/where-to-buy.yaml";

// markup
const IndexPage: React.FC = () => {
  return (
    <Layout>
      <Seo
        title="Page Not Found"
        description="The page you're looking for or the link you've hit doesn't exist. We've got the tools to fix it so feel free to try again or get in touch with us if the page is broken."
      />
      <BannerBasic title="Oops page not found" />
      <LeadIn
        summary="The page you're looking for or the link you've hit doesn't exist. We've got the tools to fix it so feel free to try again or get in touch with us if the page is broken."
      />
      <FeatureColumn grid="medium" columns="3" data={featuredBrands.items} />
      <LeadIn
        section="primary"
        brandMediaUrl="/icons/icon-mail.svg"
        brandMediaTitle="Download"
        brandMediaWidth="53px"
        summary="Contact us for further information"
        paddingSize="small"
        buttonValue="Contact"
        buttonUrl="/contact"
      />
    </Layout>
  );
};

export default IndexPage;
